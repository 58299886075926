
<template>
	<div class="filter__refinements-container">
		<div class="filter__mobile-meta display-at-e">
			<button class="filter__toggle-mobile">
				Filter
			</button>
		</div>
	</div>

	<div class="grid flex flex--wrap relative">
		<div class="grid__item a3-12 c4-12 e6-6">
			<div class="filter filter--sticky">
				<div class="filter__nav-mobile filter__nav-mobile--filter">
					<button
						class="filter__close"
						aria-label="Close filter"
					/>
					<h2>
						Filters
					</h2>
				</div>

				<div
					class="filter__heading-container"
				>
					<h2
						class="filter__heading"
						@click="toggleFilters"
					>
						Filters
					</h2>

					<template v-if="showOnlyAvailableFilter">
						<div class="filter__separate-top">
							<div class="flex">
								<label
									class="filter__lightswitch"
									:aria-label="`Toggle available ${entryNoun}`"
								>
									<input
										v-model="filtered_only_availability"
										type="checkbox"
									>
									<span class="filter__lightswitch-slider" />
								</label>
								<p class="filter__lightswitch-text">
									Only show {{ entryNoun }} with availability
								</p>
							</div>
						</div>
					</template>

					<template v-if="showOnlyTrainedFilter">
						<div class="filter__separate-top">
							<div class="flex">
								<label
									class="filter__lightswitch"
									:aria-label="`Toggle only ${entryNoun} I’m trained in`"
								>
									<input
										v-model="filtered_only_trained"
										type="checkbox"
									>
									<span class="filter__lightswitch-slider" />
								</label>
								<p class="filter__lightswitch-text">
									Only show {{ entryNoun }} I’m trained in
								</p>
							</div>
						</div>
					</template>
				</div>

				<div
					class="filters__container"
					:class="{ 'js-toggled': filters_open }"
				>
					<template v-if="showBlogCategoryFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'blog_category' ) }"
								@click="handleToggles( 'blog_category' )"
							>
								Categories
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'blog_category' ) }"
							>
								<div
									v-for="blog_category, index in blogCategories"
									:key="index"
									class="form__checkbox"
								>
									<input
										:id="'filtered-blog-category-' + index"
										v-model="filtered_blog_categories"
										type="checkbox"
										class="form__checkbox--alt"
										:value="blog_category.id"
									>
									<label :for="'filtered-blog-category-' + index">
										{{ blog_category.title }}
									</label>
								</div>
							</div>
						</div>
					</template>

					<template v-if="showYearFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'year' ) }"
								@click="handleToggles( 'year' )"
							>
								Year
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'year' ) }"
							>
								<div class="form__icon form__icon--down">
									<select
										id="filtered-year"
										v-model="filtered_year"
										class="form__select"
									>
										<option
											v-for="option, index in years"
											:key="index"
											:value="option.value"
											:selected="filtered_year === option.value"
										>
											{{ option.label }}
										</option>
									</select>
								</div>
							</div>
						</div>
					</template>

					<template v-else-if="showDateFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'date' ) }"
								@click="handleToggles( 'date' )"
							>
								Date
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'date' ) }"
							>
								<DatePickerComponent
									input-id="filtered-dates"
									:dates="filtered_date_range"
									mode="range"
									:min-date="min_date_formatted"
									@update="updateFilteredDates"
								/>

								<div class="flex flex--gap flex--col-cap-8 mb-16">
									<button
										class="button button--cal"
										:class="{ 'button--selected': setIsSelected( 'none' ) }"
										@click="clearDateFilter"
									>
										All
									</button>
									<button
										class="button button--cal"
										:class="{ 'button--selected': setIsSelected( 'week' ) }"
										@click="setDateFilterToThisWeek"
									>
										This week
									</button>
									<button
										class="button button--cal"
										:class="{ 'button--selected': setIsSelected( 'month' ) }"
										@click="setDateFilterToThisMonth"
									>
										This month
									</button>
								</div>
							</div>
						</div>
					</template>

					<template v-if="showLocationsFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'location' ) }"
								@click="handleToggles( 'location' )"
							>
								Location
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'location' ) }"
							>
								<div
									v-for="location, index in locations"
									:key="index"
									class="form__checkbox"
								>
									<input
										:id="'filtered-locations-' + index"
										v-model="filtered_locations"
										type="checkbox"
										class="form__checkbox--alt"
										:value="location.id"
									>
									<label :for="'filtered-locations-' + index">
										{{ location.title }}
									</label>
								</div>
							</div>
						</div>
					</template>

					<template v-if="showSeriesFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'serie' ) }"
								@click="handleToggles( 'serie' )"
							>
								Series
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'serie' ) }"
							>
								<div
									v-for="serie, index in series"
									:key="index"
									class="form__checkbox"
								>
									<input
										:id="'filtered-series-' + index"
										v-model="filtered_series"
										type="checkbox"
										class="form__checkbox--alt"
										:value="serie.id"
									>
									<label :for="'filtered-series-' + index">
										{{ serie.title }}
									</label>
								</div>
							</div>
						</div>
					</template>

					<template v-if="showToolTypeFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'tool_type' ) }"
								@click="handleToggles( 'tool_type' )"
							>
								Type of tool
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'tool_type' ) }"
							>
								<div
									v-for="tool_type, index in toolTypes"
									:key="index"
									class="form__checkbox"
								>
									<input
										:id="'filtered-tool-types-' + index"
										v-model="filtered_tool_types"
										type="checkbox"
										class="form__checkbox--alt"
										:value="tool_type.id"
									>
									<label :for="'filtered-tool-types-' + index">
										{{ tool_type.title }}
									</label>
								</div>
							</div>
						</div>
					</template>

					<template v-if="showTrafficLightFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'traffic_light' ) }"
								@click="handleToggles( 'traffic_light' )"
							>
								Traffic Light Value
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'traffic_light' ) }"
							>
								<div
									v-for="traffic_light, index in traffic_light_values"
									:key="index"
									class="form__checkbox form__checkbox--tag"
								>
									<input
										:id="'filtered-traffic-lights-' + index"
										v-model="filtered_traffic_lights"
										type="checkbox"
										class="form__checkbox--alt"
										:value="traffic_light.value"
									>
									<label
										:for="'filtered-traffic-lights-' + index"
										class="form__tag-label"
									>
										<span :class="'form__tag-inner form__tag-inner--' + traffic_light.value">{{ traffic_light.label }}</span>
									</label>
								</div>
							</div>
						</div>
					</template>

					<template v-if="showResearchThemeFilter">
						<div class="filter__separate filter__separate--s">
							<button
								class="filter__toggle toggler"
								:class="{ 'toggler--toggled' : getIsToggled( 'research_theme' ) }"
								:aria-expanded="String( getIsToggled( 'research_theme' ) )"
								@click="handleToggles( 'research_theme' )"
							>
								Research themes
							</button>
							<div
								class="filter__content"
								:class="{ 'js-toggled': getIsToggled( 'research_theme' ) }"
							>
								<div
									v-for="research_theme, index in researchThemes"
									:key="index"
									class="form__checkbox"
								>
									<input
										:id="'filtered-tool-types-' + index"
										v-model="filtered_research_themes"
										type="checkbox"
										class="form__checkbox--alt"
										:value="research_theme.id"
									>
									<label :for="'filtered-tool-types-' + index">
										{{ research_theme.title }}
									</label>
								</div>
							</div>
						</div>
					</template>
				</div>
			</div>
		</div>
		<div
			ref="results"
			class="grid__item a9-12 c8-12 e6-6"
		>
			<div class="relative">
				<template v-if="filtered_entries.length === 0">
					<NoResultsComponent
						:text="no_result_text"
						@clear="clearAllFilters()"
					/>
				</template>
				<p
					v-if="listingMode !== 'event-simple' && listingMode !== 'event' && listingMode !== 'blog'"
					class="filter__results"
				>
					{{ filtered_entries.length }} result{{ filtered_entries.length > 1 ? 's' : '' }}
				</p>
				<template v-if="listingMode === 'event-simple'">
					<div class="grid flex flex--wrap">
						<div
							v-for="entry in paginated_entries"
							:key="entry.id"
							class="grid__item a4-12 d3-6 e6-6"
						>
							<a
								:href="entry.url"
								class="listing-simple listing-simple--bordered"
							>
								<div class="listing-simple__inner">
									<div class="listing-simple__image">
										<img
											v-if="entry.activity_image.length"
											:src="entry.activity_image[0].url"
											:width="entry.activity_image[0].width"
											:height="entry.activity_image[0].height"
											:alt="entry.activity_image[0].alt"
										>
										<div
											v-else
											class="event-listing__image-placeholder"
										/>
									</div>

									<div class="listing-simple__content">
										<div class="listing-simple__date">
											{{ getDateString( entry ) }}
											<span class="ellipse">&bull;</span>
											{{ getTimeString( entry ) }}
										</div>

										<h3 class="listing-simple__title">
											{{ entry.title }}
										</h3>

										<div v-if="entry.series.length">
											<span
												v-for="serie in entry.series"
												:key="serie.id"
												class="tag tag--border"
											>
												{{ serie.title }}
											</span>
										</div>
									</div>
								</div>

							</a>
						</div>
					</div>
				</template>
				<template v-else-if="listingMode === 'event'">
					<div class="grid flex flex--wrap">
						<div
							v-for="entry in paginated_entries"
							:key="entry.id"
							class="grid__item a6-12 e6-6"
						>
							<a
								:href="entry.url"
								class="listing-simple listing-simple--bordered"
							>
								<div class="listing-simple__inner">

									<div class="listing-simple__image">
										<img
											v-if="entry.activity_image.length"
											:src="entry.activity_image[0].url"
											:width="entry.activity_image[0].width"
											:height="entry.activity_image[0].height"
											:alt="entry.activity_image[0].alt"
										>
										<div
											v-else
											class="listing-simple__image-placeholder"
										/>
									</div>

									<div class="listing-simple__content">
										<div>
											<h3 class="listing-simple__title listing-simple__title--event">
												{{ entry.title }}
											</h3>
											<p class="listing-simple__description">
												{{ entry.listing_description }}
											</p>


											<div class="event-listing__data-container">
												<div class="event-listing__data event-listing__data--date">
													{{ getDateString( entry ) }}
												</div>
												<div class="event-listing__data event-listing__data--time">
													{{ getTimeString( entry ) }}
												</div>
												<div
													v-if="entry.location.length"
													class="event-listing__data event-listing__data--location"
												>
													{{ entry.location[0].title }}
												</div>
											</div>
										</div>

										<span
											class="button button--center"
											:class="getEventCtaClassModifier( entry )"
										>
											{{ getEventCtaText( entry ) }}
										</span>
									</div>
								</div>
							</a>
						</div>
					</div>
				</template>
				<template v-else-if="listingMode === 'research'">
					<div class="grid flex flex--wrap">
						<div
							v-for="entry in paginated_entries"
							:key="entry.id"
							class="grid__item a6-12 e6-6"
						>
							<a
								:href="entry.url"
								class="listing-simple listing-simple--spaced listing-simple--bordered"
							>
								<div class="listing-simple__inner">

									<div class="listing-simple__image">
										<img
											v-if="entry.listing_image.length"
											:src="entry.listing_image[0].url"
											:width="entry.listing_image[0].width"
											:height="entry.listing_image[0].height"
											:alt="entry.listing_image[0].alt"
										>
										<div
											v-else
											class="listing-simple__image-placeholder listing-simple__image-placeholder--research"
										/>
									</div>

									<div class="listing-simple__content listing-simple__content--start">
										<h3 class="listing-simple__title">
											{{ entry.title }}
										</h3>
										<p>{{ entry.listing_description }}</p>
									</div>

								</div>
							</a>
						</div>
					</div>
				</template>
				<!-- Blog -->
				<template v-else-if="listingMode === 'blog'">
					<div class="blog grid flex flex--wrap">
						<div
							v-for="entry in paginated_entries"
							:id="`fill-${entry.grid_half_fill}`"
							:key="entry.id"
							:class="{
								'grid__item': true,
								'feat a12-12': entry.featured,
								'a4-12': ((!entry.grid_half_fill) && (!entry.featured) || is_filtered),
								'a6-12': (entry.grid_half_fill) && (!entry.featured) && !is_filtered,
								'e3-6 f6-6': (!entry.featured) && !is_filtered
							}"
						>
							<a
								v-if="entry.featured"
								:href="entry.url"
								class="content__block content__block--listing"
							>
								<div class="content__columns">
									<div class="content__image">
										<img
											v-if="entry.listing_image.length"
											:src="entry.listing_image[0].url"
											:width="entry.listing_image[0].width"
											:height="entry.listing_image[0].height"
											:alt="entry.listing_image[0].alt"
										>
									</div>
									<div class="content__content">
										<div class="flex mb-48 mb-16-e">
											<span class="tag tag--border">Featured</span>
										</div>
										<div class="mb-48 mb-0-e">
											<p class="listing-simple__date">
												{{ entry.date }}
											</p>
											<h2 class="content__title">
												{{ entry.title }}
											</h2>
										</div>
										<div class="flex">
											<span
												class="button button--outline button-inside-flex"
												:aria-label="'Visit the ' + entry.title + ' page'"
											>
												Read the full article
											</span>
										</div>
									</div>
								</div>
							</a>
							<a
								v-else
								:href="entry.url"
								class="listing-simple listing-simple--spaced listing-simple--bordered"
							>
								<div class="listing-simple__inner">
									<div class="listing-simple__image">
										<img
											v-if="entry.listing_image.length"
											:src="entry.listing_image[0].url"
											:width="entry.listing_image[0].width"
											:height="entry.listing_image[0].height"
											:alt="entry.listing_image[0].alt"
										>
										<div
											v-else
											class="event-listing__image-placeholder"
										/>
									</div>
									<div class="listing-simple__content listing-simple__content--start">
										<p class="listing-simple__date">
											{{ entry.date }}
										</p>
										<h2 class="listing-simple__title listing-simple__title--blog">
											{{ entry.title }}
										</h2>
									</div>
								</div>
							</a>
						</div>
					</div>
				</template>
				<template v-else>
					<div class="grid flex flex--wrap flex--row-gap">
						<div
							v-for="entry in paginated_entries"
							:key="entry.id"
							class="grid__item a6-12 e6-6"
						>
							<a
								:href="entry.url"
								class="tool-listing"
							>
								<div class="tool-listing__inner">
									<div class="tool-listing__image">
										<img
											v-if="entry.class_image.length"
											:src="entry.class_image[0].url"
											:width="entry.class_image[0].width"
											:height="entry.class_image[0].height"
											:alt="entry.class_image[0].alt"
										>
										<div
											v-else
											class="tool-listing__image-placeholder"
										/>
									</div>
									<div class="tool-listing__content">
										<div class="tool-listing__text-content">
											<h3 class="tool-listing__title">
												{{ entry.title }}
											</h3>
											<div
												v-if="entry.listing_description"
												class="tool-listing__description typeset"
											>
												{{ entry.listing_description }}
											</div>
										</div>
										<div class="tool-listing__tags">
											<div
												class="tag"
												:class="'tag--' + entry.traffic_light_value"
											>
												{{ getHumanTrafficLightValue( entry.traffic_light_value ) }}
											</div>
											<div
												v-if="entry.tool_class.length > 0"
												class="tag tag--border"
											>
												{{ entry.tool_class[0].title }}
											</div>
											<template v-for="loc in entry.location">
												<div
													v-if="loc.id === 11 || loc.id === 13"
													class="tag tag--border"
												>
													{{ loc.id === 11 ? 'Bloomsbury' : 'Stratford' }}
												</div>
											</template>
										</div>
									</div>
								</div>
							</a>
						</div>
					</div>
				</template>
				<PaginationComponent
					v-if="page_count > 1"
					:page-count="page_count"
					:current-page="current_page"
					@page-decrement="decrementPage"
					@page-increment="incrementPage"
					@page-change="changePage"
				/>
			</div>
		</div>
	</div>
</template>

<script>

import { storeToRefs } from 'pinia';

import moment from 'moment';

import { getNounFilterStoreFactory } from './stores/filters';

import DatePickerComponent from '../shared/components/DatePickerComponent.vue';
import PaginationComponent from '../manage/components/PaginationComponent.vue';
import NoResultsComponent from '../shared/components/NoResultsComponent.vue';

import {
	ACTIVITY_TYPE_PUBLIC_EVENT,
} from '../../constants.js';

export default {
	components: {
		DatePickerComponent,
		PaginationComponent,
		NoResultsComponent,
	},
	props: {
		heading: {
			required: true,
			type: String,
		},
		entries: {
			required: true,
			type: Array,
		},
		entryNoun: {
			required: true,
			type: String,
		},
		showOnlyAvailableFilter: {
			required: true,
			type: Boolean,
		},
		showOnlyTrainedFilter: {
			required: true,
			type: Boolean,
		},
		showDateFilter: {
			required: true,
			type: Boolean,
		},
		showYearFilter: {
			required: true,
			type: Boolean,
		},
		listingMode: { // event, event-simple, tool, blog, research
			required: true,
			type: String,
		},
		showLocationsFilter: {
			required: true,
			type: Boolean,
		},
		showToolTypeFilter: {
			required: true,
			type: Boolean,
		},
		showSeriesFilter: {
			required: true,
			type: Boolean,
		},
		showTrafficLightFilter: {
			required: true,
			type: Boolean,
		},
		showSimpleListing: {
			required: true,
			type: Boolean,
		},
		showResearchThemeFilter: {
			required: true,
			type: Boolean,
		},
		showBlogCategoryFilter: {
			required: true,
			type: Boolean,
		},
		locations: {
			required: true,
			type: Array,
		},
		series: { // plural
			required: true,
			type: Array,
		},
		toolTypes: {
			required: true,
			type: Array,
		},
		researchThemes: {
			required: true,
			type: Array,
		},
		blogCategories: {
			required: true,
			type: Array,
		},
		trainedToolClassIds: {
			required: false,
			type: Array,
			default: () => [],
		},
		pageSize: {
			type: Number,
			default: 18
		},
		queryParams: {
			required: true,
			type: Object,
			default: () => {},
		}
	},
	setup( props ) {
		const filter_store = getNounFilterStoreFactory( props.entryNoun )();
		filter_store.setDefaults( props.queryParams );
		const {
			filtered_only_availability,
			filtered_only_trained,
			filtered_year,
			filtered_date_range,
			filtered_locations,
			filtered_series,
			filtered_tool_types,
			filtered_traffic_lights,
			filtered_research_themes,
			filtered_blog_categories,
		} = storeToRefs( filter_store );
		return {
			filtered_only_availability,
			filtered_only_trained,
			filtered_year,
			filtered_date_range,
			filtered_locations,
			filtered_series,
			filtered_tool_types,
			filtered_traffic_lights,
			filtered_research_themes,
			filtered_blog_categories,
		};
	},
	data() {
		const years = [
			{ label: 'Select year', value: '' },
		];
		const from_year = {
			blog: 2010,
			'past-events': 2006,
		}[this.entryNoun] || 2023;
		for ( let i = ( new Date() ).getFullYear(); i >= from_year; i-- ) {
			years.push( { label: i.toString(), value: i.toString() } );
		}

		// Week mode doesn't work properly if min date is outside of the week.
		// We can't just use the 1st of the month because the start of the week
		// might be inside last month.
		let min_datepicker_date = moment().date( 1 );
		if ( moment().startOf( 'week' ).isBefore( min_datepicker_date ) ) {
			min_datepicker_date = moment().startOf( 'week' );
		}

		return {
			current_page: 0,

			min_date_formatted: min_datepicker_date.format( 'YYYY-MM-DD' ),

			filters_open: false,
			year_filter_open: this.filtered_year !== '',
			date_filter_open: true,
			location_filter_open: this.filtered_locations.length > 0,
			series_filter_open: this.filtered_series.length > 0,
			tool_type_filter_open: this.filtered_tool_types.length > 0,
			traffic_light_filter_open: this.filtered_traffic_lights.length > 0,
			research_theme_filter_open: this.filtered_research_themes.length > 0,
			blog_category_filter_open: this.filtered_blog_categories.length > 0,

			years: years,
			traffic_light_values: [
				{ value: 'red', label: 'Red' },
				{ value: 'yellow', label: 'Yellow' },
				{ value: 'green', label: 'Green' },
			],

			filter_buttons: [
				{
					name: 'year',
					is_open: this.filtered_year !== '',
				},
				{
					name: 'date',
					is_open: true,
				},
				{
					name: 'location',
					is_open: this.filtered_locations.length > 0,
				},
				{
					name: 'serie',
					is_open: this.filtered_series.length > 0,
				},
				{
					name: 'tool_type',
					is_open: this.filtered_tool_types.length > 0,
				},
				{
					name: 'traffic_light',
					is_open: this.filtered_traffic_lights.length > 0,
				},
				{
					name: 'research_theme',
					is_open: this.filtered_research_themes.length > 0,
				},
				{
					name: 'blog_category',
					is_open: this.filtered_blog_categories.length > 0,
				},
			],
		};
	},
	computed: {
		page_count() {
			return Math.ceil( this.filtered_entries.length / this.pageSize );
		},
		paginated_entries() {
			const page_start = this.current_page * this.pageSize;
			const page_end = page_start + this.pageSize;
			return this.filtered_entries.slice( page_start, page_end );
		},
		is_filtered() {
			// You may need more conditions here?
			return this.filtered_blog_categories.length > 0 || this.filtered_year !== '';
		},
		no_result_text() {
			switch ( this.entryNoun ) {
				case 'training':
					return 'Unfortunately there are no training sessions for this tool right now. But don’t fear, we will publish fresh sessions on the last Friday of the month at noon. Please check back then, or <a class="a" href="/members/events">browse our other events</a>.';

				case 'events':
				case 'past-events':
					return 'Unfortunately there are no events matching your search.';

				case 'research':
					return 'Unfortunately there is no research matching your search.';

				case 'blog':
					return 'Unfortunately there are no articles matching your search.';

				case 'tools':
					return 'Unfortunately there are no tools matching your search.';

				default:
					return 'Unfortunately there are no results for your search';
			}
		},
		filtered_entries() {
			this.changePage( 0, false );

			return this.entries.filter( entry => {
				let show_entry = true;
				const activity_end_date = this.showDateFilter ? ( entry.end_date === null ? entry.start_date : entry.end_date ) : null;
				if (
					this.showOnlyAvailableFilter
					&& this.filtered_only_availability
					&& !entry.capacity_available
					&& !entry.waitlist_available
				) {
					show_entry = false;
				}
				if (
					this.showOnlyTrainedFilter
					&& this.filtered_only_trained
					&& (
						!this.trainedToolClassIds.includes( entry.id )
						&& !entry.ancestor_ids.reduce( ( acc, id ) => {
							return acc || this.trainedToolClassIds.includes( id );
						}, false )
					)
				) {
					show_entry = false;
				}
				if ( this.showYearFilter ) {
					if (
						this.filtered_year !== ''
						&& entry.year !== this.filtered_year
					) {
						show_entry = false;
					}
				} else if (
					this.showDateFilter
					&& (
						(
							this.filtered_date_range.length > 1
							&& (
								activity_end_date < this.filtered_date_range[0]
								|| entry.start_date > this.filtered_date_range[1]
							)
						)
						|| (
							this.filtered_date_range.length === 1
							&& (
								activity_end_date < this.filtered_date_range[0]
								|| entry.start_date > this.filtered_date_range[0]
							)
						)
					)
				) {

					show_entry = false;
				}
				if (
					this.showLocationsFilter
					&& this.filtered_locations.length > 0
				) {
					show_entry = show_entry && entry.location.length && entry.location.reduce( ( acc, item ) => {
						return acc || this.filtered_locations.includes( item.id );
					}, false );
				}
				if (
					this.showSeriesFilter
					&& this.filtered_series.length > 0
				) {
					show_entry = show_entry && entry.series.length && entry.series.reduce( ( acc, item ) => {
						return acc || this.filtered_series.includes( item.id );
					}, false );
				}
				if (
					this.showToolTypeFilter
					&& this.filtered_tool_types.length > 0
				) {
					show_entry = show_entry && entry.tool_class.length && entry.tool_class.reduce( ( acc, item ) => {
						return acc || this.filtered_tool_types.includes( item.id );
					}, false );
				}
				if (
					this.showTrafficLightFilter
					&& this.filtered_traffic_lights.length > 0
				) {
					show_entry = show_entry && this.filtered_traffic_lights.includes(
						entry.traffic_light_value
					);
				}
				if (
					this.showResearchThemeFilter
					&& this.filtered_research_themes.length > 0
				) {
					show_entry = show_entry && entry.research_themes && entry.research_themes.reduce( ( acc, item ) => {
						return acc || this.filtered_research_themes.includes( item.id );
					}, false );
				}
				if (
					this.showBlogCategoryFilter
					&& this.filtered_blog_categories.length > 0
				) {
					show_entry = show_entry && entry.blog_categories && entry.blog_categories.reduce( ( acc, item ) => {
						return acc || this.filtered_blog_categories.includes( item.id );
					}, false );
				}
				return show_entry;
			} );
		},
	},
	watch: {
		filtered_entries: function() {
			this.setBlogEntriesListingLayout();
		},
		filtered_blog_categories: function() {
			this.resetPageUrl();
		},
		filtered_research_themes: function() {
			this.resetPageUrl();
		},
		filtered_tool_types: function() {
			this.resetPageUrl();
		},
		filtered_traffic_lights: function() {
			this.resetPageUrl();
		},
		filtered_locations: function() {
			this.resetPageUrl();
		}
	},
	beforeMount() {
		this.setBlogEntriesListingLayout();
	},
	methods: {
		resetPageUrl() {
			// clean up query param after filtering
			const url = window.location.href.split( '?' )[0];
			window.history.replaceState( {}, document.title, url );
		},
		changePage( index, scroll_to_top = true ) {
			this.current_page = index;
			if ( scroll_to_top && this.$refs.results ) {
				this.$refs.results.scrollIntoView();
			}
		},
		decrementPage() {
			if ( this.current_page > 0 ) {
				this.changePage( this.current_page - 1 );
			}
		},
		incrementPage() {
			if ( this.current_page < ( this.page_count - 1 ) ) {
				this.changePage( this.current_page + 1 );
			}
		},
		getDateString( entry ) {
			if ( entry.end_date === null || moment( entry.end_date ).diff( entry.start_date, 'days' ) === 0 ) {
				return moment( entry.start_date ).format( 'dddd Do MMMM, YYYY' );
			}
			return moment( entry.start_date ).format( 'dddd Do MMMM, YYYY' )
				+ ' — '
				+ moment( entry.end_date ).format( 'dddd Do MMMM, YYYY' );
		},
		getTimeString( entry ) {
			return entry.start_time + ' — ' + entry.end_time;
		},
		toggleFilters() {
			this.filters_open = !this.filters_open;
		},
		toggleYearFilter() {
			this.year_filter_open = !this.year_filter_open;
		},
		toggleDateFilter() {
			this.date_filter_open = !this.date_filter_open;
		},
		toggleLocationFilter() {
			this.location_filter_open = !this.location_filter_open;
		},
		toggleSeriesFilter() {
			this.series_filter_open = !this.series_filter_open;
		},
		toggleToolTypeFilter() {
			this.tool_type_filter_open = !this.tool_type_filter_open;
		},
		toggleTrafficLightFilter() {
			this.traffic_light_filter_open = !this.traffic_light_filter_open;
		},
		toggleResearchThemeFilter() {
			this.research_theme_filter_open = !this.research_theme_filter_open;
		},
		toggleBlogCategoryFilter() {
			this.blog_category_filter_open = !this.blog_category_filter_open;
		},
		updateFilteredDates( date_range ) {
			this.filtered_date_range = date_range;
		},
		clearDateFilter() {
			this.updateFilteredDates( [] );
		},
		setDateFilterToThisWeek() {
			this.updateFilteredDates( [
				moment().startOf( 'isoWeek' ).format( 'YYYY-MM-DD' ),
				moment().endOf( 'isoWeek' ).format( 'YYYY-MM-DD' )
			] );
		},
		setDateFilterToThisMonth() {
			this.updateFilteredDates( [
				moment().date( 1 ).format( 'YYYY-MM-DD' ),
				moment().date( 1 ).add( 1, 'months' ).subtract( 1, 'days' ).format( 'YYYY-MM-DD' )
			] );
		},
		getHumanTrafficLightValue( raw_value ) {
			return raw_value.slice( 0, 1 ).toUpperCase() + raw_value.slice( 1 );
		},
		getEventCtaText( entry ) {
			if (
				entry.entry_type === ACTIVITY_TYPE_PUBLIC_EVENT
				&& entry.booking_link_text?.length
			) {
				return entry.booking_link_text;
			}
			if ( entry.bookings_available_from > moment().format( 'YYYY-MM-DD HH:mm:ss' ) ) {
				const date = moment( entry.bookings_available_from );
				const date_string = date.format( 'ddd Do MMM' );
				const time_string = date.format( 'HH:mm' );
				if ( time_string === '00:00' ) {
					return `Book from ${date_string}`;
				}
				return `Book from ${date_string} ${time_string}`;
			}
			if ( entry.capacity_available ) {
				return 'Book now';
			}
			if ( entry.waitlist_available ) {
				return 'Join waiting list';
			}
			return 'Fully booked';
		},
		getEventCtaClassModifier( entry ) {
			if ( entry.bookings_available_from > moment().format( 'YYYY-MM-DD HH:mm:ss' ) ) {
				return 'button--light';
			}
			if ( entry.capacity_available ) {
				return '';
			}
			if ( entry.waitlist_available ) {
				return 'button--secondary';
			}
			return 'button--light';
		},
		arrangeBlogEntriesByRow( entries_for_each_row ) {
			// applies the correct grid class based on how many entries
			// should be displayed in each row
			if ( !entries_for_each_row.length > 0 ) {
				return;
			}

			let count = 0;
			for ( let j = 0; j <= entries_for_each_row.length; j++ ) {
				const row_length = entries_for_each_row[j];
				if ( row_length === 0 ) {
					count++;
				} else {
					for ( let k = 0; k < entries_for_each_row[j]; k++ ) {
						if ( entries_for_each_row[j] === 2 ) {
							this.filtered_entries[count].grid_half_fill = true;
						}
						count++;
					}
				}
			}
		},
		randomiseRepeatedRows( entries_for_each_row, row_total ) {
			const randomised_rows = [];
			let skip_amount = 0;
			if ( entries_for_each_row.length < 3 ) {
				for ( let i = 0; i < entries_for_each_row.length; i++ ) {
					randomised_rows.push( entries_for_each_row[i] );
				}
				return randomised_rows;
			}
			for ( let i = 0; i <= entries_for_each_row.length - 3; i++ ) {
				if ( skip_amount > 0 ) {
					skip_amount = skip_amount - 1;
				} else {
					if ( entries_for_each_row[i] === row_total && entries_for_each_row[i + 1] === row_total ) {
						if ( row_total === 3 ) {
							if ( i <= entries_for_each_row.length - 4 && entries_for_each_row[i + 4] !== 3 && entries_for_each_row[i + 2] === 3 ) {
								randomised_rows.push( 2, 3, 2, 2 );
								skip_amount = 2;
							} else if ( ( i === 0 && entries_for_each_row.length > 4  ) || ( i > 0 && entries_for_each_row[i - 1] === 2 ) ) {
								randomised_rows.push( 3, 3 );
								skip_amount = 1;
							} else if ( entries_for_each_row[i + 2] === 3 ) {
								randomised_rows.push( 2, 3, 2, 2 );
								skip_amount = 2;
							} else {
								randomised_rows.push( entries_for_each_row[i] );
							}
						} else if ( row_total === 2 ) {
							if ( ( i === 0 && entries_for_each_row.length > 3 ) || ( i > 0 && entries_for_each_row[i - 1] === 3 ) ) {
								randomised_rows.push( 2, 2 );
								skip_amount = 1;
							} else if ( entries_for_each_row[i + 2] === 2 ) {
								randomised_rows.push( 3, 3 );
								skip_amount = 2;
							} else {
								randomised_rows.push( entries_for_each_row[i] ) ;
							}

						}
					} else {
						randomised_rows.push( entries_for_each_row[i] ) ;
					}
				}
				if ( i === entries_for_each_row.length - 3 ) { // add missing entries after end of loop
					for ( let j = i + skip_amount + 1; j < entries_for_each_row.length; j++ ) {
						randomised_rows.push( entries_for_each_row[j] );
					}
				}
			}
			return randomised_rows;
		},
		getEntriesForEachRow( blogs_between_featured ) {
			const entries_per_row = [];
			for ( let i = 0; i < blogs_between_featured.length; i++ ) {
				const total = blogs_between_featured[i];
				if ( total === 0 || total === 1 ) {
					entries_per_row.push( total );
				}  else {
					const remainder_three = total % 3;
					const remainder_two = total % 2;
					if ( remainder_three === 0 ) {
						const rows_of_three = total / 3;
						for ( let j = 0; j < rows_of_three; j++ ) {
							entries_per_row.push( 3 );
						}
					} else if ( remainder_two === 0 ) {
						const rows_of_two = total / 2;
						for ( let j = 0; j<rows_of_two; j++ ) {
							entries_per_row.push( 2 );
						}
					} else if ( remainder_three === ( 1 ) ) {
						entries_per_row.push( 2, 2 );
						const rows_of_three =  Math.floor( total / 3 ) - 1;
						for ( let j = 0; j<rows_of_three; j++ ) {
							entries_per_row.push( 3 );
						}
					} else if ( remainder_three === ( 2 ) ) {
						const rows_of_three = Math.floor( total / 3 );
						for ( let j = 0; j < rows_of_three; j++ ) {
							entries_per_row.push( 3 );
						}
						entries_per_row.push( 2 );
					}
				}
			}
			return entries_per_row;
		},
		findEntriesBetweenFeaturedTotal( entries ) {
			let count = 0;
			const blogs_between_featured = [];
			for ( let i = 0; i < entries.length; i++ ) {
				count++;
				if ( entries[i].featured ) {
					blogs_between_featured.push( count - 1 );
					if ( entries[i - 1] && !entries[i - 1].featured ) {
						blogs_between_featured.push( 0 );
					}
					count = 0;

				} else if ( i === entries.length - 1 ) {
					blogs_between_featured.push( count );
				}
			}
			return blogs_between_featured;
		},
		setBlogEntriesListingLayout() {
			let entries_leftover = this.filtered_entries;
			let final_entries = [];
			let blogs_between_featured = [];
			for ( let i = 0; i < this.page_count; i++ ) {
				if ( i === this.page_count - 1 ) {
					blogs_between_featured = this.findEntriesBetweenFeaturedTotal( entries_leftover );
				} else {
					const entries_in_page = entries_leftover.slice( 0, this.pageSize );
					entries_leftover = entries_leftover.slice( this.pageSize );
					blogs_between_featured = this.findEntriesBetweenFeaturedTotal( entries_in_page );
				}
				const entries_for_each_row = this.getEntriesForEachRow( blogs_between_featured );
				const randomised_rows = this.randomiseRepeatedRows( entries_for_each_row, 2 );
				const randomised_rows_final = this.randomiseRepeatedRows( randomised_rows, 3 );
				final_entries = final_entries.concat( randomised_rows_final );
			}
			this.arrangeBlogEntriesByRow( final_entries );
		},
		handleToggles( name ) {
			const current_filter = this.filter_buttons.find( b => b.name === name );
			current_filter.is_open = !current_filter.is_open;

			const filters_to_close = this.filter_buttons.filter( b => b.name !== name );
			filters_to_close.forEach( filter => {
				filter.is_open = false;
			} );
		},
		getIsToggled( name ) {
			return this.filter_buttons.find( b => b.name === name ).is_open;
		},
		setIsSelected( filter ) {
			if ( this.filtered_date_range.length > 0 ) {
				if ( filter === 'week' ) {
					const week_dates = [
						moment().startOf( 'isoWeek' ).format( 'YYYY-MM-DD' ),
						moment().endOf( 'isoWeek' ).format( 'YYYY-MM-DD' )
					];
					return this.filtered_date_range[0] === week_dates[0] && this.filtered_date_range[1] === week_dates[1];
				}
				if ( filter === 'month' ) {
					const month_dates = [
						moment().date( 1 ).format( 'YYYY-MM-DD' ),
						moment().date( 1 ).add( 1, 'months' ).subtract( 1, 'days' ).format( 'YYYY-MM-DD' )
					];
					return this.filtered_date_range[0] === month_dates[0] && this.filtered_date_range[1] === month_dates[1];
				}
				return false;
			} else if ( filter === 'none' ) {
				return true;
			}
			return false;
		},
		clearAllFilters() {
			this.filtered_only_availability = false;
			this.filtered_only_trained = false;
			this.filtered_year = '';
			this.filtered_locations = [];
			this.filtered_series = [];
			this.filtered_tool_types = [];
			this.filtered_traffic_lights = [];
			this.filtered_research_themes = [];
			this.filtered_blog_categories = [];
			this.filtered_date_range = [];
		}
	},
};

</script>
